<template>
  <!-- 侧边工具 -->
  <ul class="tool">
    <li class="codeLi">
      <div
        @mouseover="showCode = false"
        @mouseleave="showCode = true"
        class="codeLiImgBox"
      >
        <img
          v-if="showCode"
          src="../assets/img/homePage/serviceCode.png"
          class="codeImg"
          alt=""
        />

        <img
          v-if="!showCode"
          src="../assets/img/homePage/serviceCodeB.png"
          class="codeImg2"
          alt=""
        />
      </div>

      <transition name="fade">
        <div v-if="!showCode" class="serviceDialog">
          <div class="serviceMid">
            <img :src="erweima2" alt="" />
          </div>
        </div>
      </transition>
    </li>
    <li class="consultLi">
      <div
        @mouseover="showPhone = false"
        @mouseleave="showPhone = true"
        class="consultLiImgBox"
      >
        <img
          v-if="showPhone"
          src="../assets/img/homePage/phoneConsult.png"
          class="consultImg"
          alt=""
        />
        <img
          v-if="!showPhone"
          src="../assets/img/homePage/phoneConsultB.png"
          class="consultImg2"
          alt=""
        />
      </div>
      <transition name="fade">
        <div v-if="!showPhone" class="phoneDialog">
          <div class="phoneMid">17764086400</div>
        </div>
      </transition>
    </li>
    <li class="serviceLi">
      <div class="serviceLiImgBox" @click="jumpTo53">
        <img
          src="../assets/img/homePage/serviceImg.png"
          class="serviceImg"
          alt=""
        />
        <img
          src="../assets/img/homePage/serviceImgB.png"
          class="serviceImg2"
          alt=""
        />
      </div>
    </li>
    <li @click="toTop" class="topLi">
      <div class="topLiImgBox">
        <img src="../assets/img/homePage/arrowTop.png" class="topImg" alt="" />
        <img
          src="../assets/img/homePage/arrowTopB.png"
          class="topImg2"
          alt=""
        />
      </div>
    </li>
  </ul>
</template>


<script>

  let myDate = new Date();
  let dateNum = myDate.getDate(); 
export default {

  data() {
    return {
      showCode: true,
      showPhone: true,
	  erweima2: "http://cso-iec.oss-cn-hongkong.aliyuncs.com/cso_school/comm/code/"+dateNum+".jpg",
    };
  },
  methods: {
    // 立即报名
    jumpTo53() {
      // console.log("666");
      // window.location.href = "https://www.bilibili.com";
      window.open(
        "http://yingkebao.top/web/formview/61c54573fc918f6aacd9e2cf"
      );
      //this.dialogVisible = true;
    },
    // 点击回到顶部按钮
    toTop() {
      // document.documentElement.scrollTop = 0;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      // let top = document.querySelector("#top");
      // console.log(top);
      // top.scrollIntoView({
      //   behavior: "smooth",
      //   block: "start",
      // });
    },
    changeCode() {
      this.showCode = !this.showCode;
    },
  },
};
</script>

<style lang="scss" scoped>
/* 侧边工具 */
.tool {
  position: fixed;
  right: 91rem;
  top: 286rem;
  padding: 10rem;
  z-index: 9999;
}
.fade-enter-active,
.fade-leave-active {
  // transition: opacity 0.5s;
  opacity: 1;
  // transform: translateX(0);
  transition-duration: 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  // transform: translateX(100%);
}
.tool li {
  margin-bottom: 30rem;
  padding-bottom: 20rem;
  border-bottom: 1rem solid #ccc;
  cursor: pointer;
}
.tool li:last-child {
  border: none;
  padding-bottom: 0rem;
  margin-bottom: 0;
}
.tool .codeLi .serviceDialog {
  width: 126rem;
  height: 120rem;
  position: absolute;
  top: -25rem;
  left: -135rem;
  overflow: hidden;
  // width: 100%;
  // height: 100%;

  /* display: none; */
  // opacity: 0;
  // transform: translateX(85%);
  // transition: 0.5s;
  background: url("../assets/img/homePage/serviceDialog.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.tool .serviceDialog .serviceMid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110rem;
  height: 110rem;
  margin: 5rem;
  background: #fff;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  // line-height: 110rem;
  // text-align: center;
}
// .tool li:nth-of-type(1):hover .serviceDialog {
//   /* display: block; */
//   transform: translateX(0);
//   opacity: 1;
// }
.tool .phoneDialog {
  width: 206rem;
  height: 60rem;
  position: absolute;
  top: 95rem;
  left: -215rem;
  background: url("../assets/img/homePage/phoneDialog.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  line-height: 60rem;
  font-weight: bold;
  font-style: italic;
  font-size: 24rem;
  /* display: none; */
  // opacity: 0;
  // transform: translateX(90%);
  // transition: 0.5s;
}
// .tool li:nth-of-type(2):hover .phoneDialog {
//   /* display: block; */
//   transform: translateX(0);
//   opacity: 1;
// }
.tool .codeLi .codeLiImgBox {
  position: relative;
  padding-bottom: 45rem;
  .codeImg,
  .codeImg2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // transition: 0.5s;
  }
}
// .tool .codeLi:hover .codeImg {
//   // display: none;
//   opacity: 0;
// }
// .tool .codeLi:hover .codeImg2 {
//   // display: block;
//   opacity: 1;
// }
// .tool .codeImg2 {
//   // display: none;
//   opacity: 0;
// }
.tool .consultLi .consultLiImgBox {
  position: relative;
  padding-bottom: 45rem;
  .consultImg,
  .consultImg2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
  }
}
.tool .consultLi:hover .consultImg {
  // display: none;
  opacity: 0;
}
.tool .consultLi:hover .consultImg2 {
  // display: block;
  opacity: 1;
}
.tool .consultImg2 {
  // display: none;
  opacity: 0;
}
.tool .serviceLi .serviceLiImgBox {
  position: relative;
  padding-bottom: 45rem;
  .serviceImg,
  .serviceImg2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
  }
}
.tool .serviceLi:hover .serviceImg {
  // display: none;
  opacity: 0;
}
.tool .serviceLi:hover .serviceImg2 {
  // display: block;
  opacity: 1;
}
.tool .serviceImg2 {
  // display: none;
  opacity: 0;
}
// .tool .topLi .topLiImgBox {
//   position: relative;
//   padding-bottom: 45rem;
//   .topImg,
//   .topImg2 {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     transition: 0.5s;
//   }
// }
.tool .topLi:hover .topImg {
  display: none;
}
.tool .topLi:hover .topImg2 {
  display: block;
}
.tool .topImg2 {
  display: none;
}
</style>
