<template>
  <div id="app">
    <nav>
      <router-link to="/"></router-link>
    </nav>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      screenWidth: document.body.clientWidth,
    };
  },

  methods: {
    // isMobile() {
    //   let flag = navigator.userAgent.match(
    //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    //   );

    //   return flag;
    // },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
  },
  watch: {
    screenWidth(newVal) {
      // console.log("newVal", newVal);
      if (newVal < 1000) {
        window.location = this.$mUrl;
      }
      // console.log('old', old);
      // this.boxWscreenWidthidth = document.body.clientWidth;
      // console.log('new', this.boxWidth);
    },

    // if (window.screen.width < 1000) {
    //   alert("移动端");
    //   // let aaa = this.isMobile;
    //   // console.log(aaa, "aaa");
    //   // window.location = this.$mUrl;
    //   // console.log(this.$mUrl, "$mUrl");
    // }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        // this.screenHeight = document.body.clientHeight;
      })();
    };

    // // if (this.isMobile) {
    // if (window.screen.width < 1000) {
    //   alert("移动端");
    //   // let aaa = this.isMobile;
    //   // console.log(aaa, "aaa");
    //   // window.location = this.$mUrl;
    //   // console.log(this.$mUrl, "$mUrl");
    // } else {
    //   alert("pc端");
    //   // this.$router.replace("/m_index");
    // }
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      //alert(navigator.userAgent);
      // window.location.href = "iPhone.html";
      window.location = this.$mUrl;
    } else if (/(Android)/i.test(navigator.userAgent)) {
      //alert(navigator.userAgent);
      // window.location.href = "Android.html";
      window.location = this.$mUrl;
    } else {
      // window.location.href = "pc.html";
      // alert("pc端");
    }
  },
};
</script>
<style lang="scss" scoped>
#app {
  min-width: 1000px;
  max-width: 1920px;
  -webkit-text-size-adjust: none;
}
</style>
