import request from "@/utils/request";

export function getHomepage(data) {
  return request({
    url: "/web",
    method: "post",
    data,
  });
}

// 国际学分项目
export function getcredit(data) {
  return request({
    url: "/web/credit",
    method: "post",
    data,
  });
}

// 课程列表
export function classList(data) {
  return request({
    url: "/web/course",
    method: "post",
    data,
  });
}

// 大使视频展示
export function ambassador(data) {
  return request({
    url: "/web/ambassador",
    method: "post",
    data,
  });
}

// 教授信息
export function professor(data) {
  return request({
    url: "/web/professor",
    method: "post",
    data,
  });
}

// 课程顾问信息
export function adviser(data) {
  return request({
    url: "/web/adviser",
    method: "post",
    data,
  });
}

// 关于cso
export function aboutcso(data) {
  return request({
    url: "/web/about",
    method: "post",
    data,
  });
}

// 精彩活动内容
export function activity(data) {
  return request({
    url: "/web/activity",
    method: "post",
    data,
  });
}

// 查询热门课程数据
export function hotList(data) {
  return request({
    url: "/web/hotCourse",
    method: "post",
    data,
  });
}
