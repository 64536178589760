import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "../src/assets/css/common.css";
import "../src/assets/css/quill.css";
import "animate.css";

Vue.config.productionTip = false;
Vue.use(ElementUI);

import axios from "axios";
// axios.defaults.baseURL = "/api";
// axios.defaults.baseURL = "http://58.48.199.198:10030";
//axios.defaults.baseURL = "http://test.csasedu.com:10030";//线上测试地址
//Vue.prototype.$fk_URL = "http://test.csasedu.com:10030/#/"; //headerNav里面跳转的线上地址，本地测试要改成自己的IP
axios.defaults.baseURL = "https://www.csoschool.com"; //线上地址
Vue.prototype.$fk_URL = "https://www.csoschool.com/#/"; //headerNav里面跳转的线上地址，本地测试要改成自己的IP

// 傻逼配了2个baseURL
axios.defaults.timeout = 5000;
Vue.prototype.$http = axios;

// 移动端跳转的URL地址
// Vue.prototype.$mUrl = "http://58.48.199.198:10030/mobile/index.html#/";
//Vue.prototype.$mUrl = "http://test.csasedu.com:10030/mobile/index.html#/";
Vue.prototype.$mUrl = "https://www.csoschool.com/mobile/index.html#/";

// 富文本编辑器插件
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);

// 日期格式修改
import moment from "moment";
Vue.filter("dataformat", (v) => {
  return moment(v).format("YYYY-MM-DD");
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
