<template>
  <div class="homePage">
    <!-- 头部轮播样式 -->
    <div class="head">
      <el-carousel :interval="3500" arrow="always" height="100vh">
        <headerNav :headerIndex="0"></headerNav>
        <el-carousel-item v-for="(item, index) in carouselList" :key="index">
          <img :src="item.pictureUrl" alt="" />
          <!-- <div class="carouselText">
            <h2>{{ item.pictureBtitle }}</h2>
            <div class="line"></div>
            <div v-html="item.pictureContent" class="pictureContent"></div>
            
          </div> -->
          <!-- <div class="showR"></div> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 暑校简介样式 -->
    <div class="w introduce">
      <div class="boxLeft">
        CSO国际暑校成立于2018年，致力于为留学生提供专业优质并且符合专业学习要求的国际教学体验。CSO引入海外优质教学体系，与旧金山州立大学、北亚利桑那大学、上海交通大学、特莱恩大学、上海理工大学等海内外名校建立深度战略合作，打造多个国际学分项目，覆盖全专业领域近3000+课程。知名学府教授专项授课，全方位满足学员的学分需求。同时，CSO开创陪伴式教育理念，打造3v1全阶段服务体系，开拓留学背景提升增值项目，旨在用更加全面优质的教学服务，帮助更多留学生走向世界舞台，成为拥有国际竞争力的精英人才。
      </div>
      <div class="boxRight">
        <el-carousel
          :interval="2000"
          height="250rem"
          indicator-position="outside"
          arrow="always"
        >
          <el-carousel-item
            v-for="(item, index) in carouselRightArr"
            :key="index"
          >
            <span>{{ item.topTips }}</span>
            <p>{{ item.botTips }}</p>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 学分项目样式 -->
    <div class="crediProject">
      <img :src="crediBg" class="crediBg" alt="" />
      <div class="crediBox">
        <div class="crediLeft">
          <h2>学分项目</h2>
          <div class="underLine"></div>
          <ul>
            <li
              v-for="(item, index) in crediArr"
              :key="index"
              :class="{ active: crediArrIndex === index }"
              @click="clickSchool(item, index)"
            >
              <!-- {{ item.id }} -->
              <!-- <div class="linebox">
              <transition name="slide-fade">
                <span v-show="crediArrIndex === index"></span>
              </transition>
            </div> -->

              <!-- <div class="creditName">{{ item.creditName }}</div> -->
              <div class="creditName">
                <div class="item">{{ item.creditName }}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="crediRight">
          <div class="outBox" v-for="(item, index) in crediArr" :key="index">
            <transition name="fade">
              <div class="crediRightBox" v-if="index == crediArrIndex">
                <div class="crediHeaderInfo">
                  <img :src="rightInfo.creditLogo" alt="" />
                  <h3>{{ rightInfo.creditName }}</h3>
                </div>
                <div
                  class="creditContent"
                  v-html="rightInfo.creditContent"
                ></div>
              </div>
            </transition>

            <!-- <div class="crediRightBox" v-if="index == crediArrIndex">
              <div class="crediHeaderInfo">
                <img :src="rightInfo.creditLogo" alt="" />
                <h3>{{ rightInfo.creditName }}</h3>
              </div>
              <div class="creditContent" v-html="rightInfo.creditContent"></div>
            </div> -->
          </div>
          <!-- <div class="crediRightBox">
            <div class="crediHeaderInfo">
              <img :src="rightInfo.creditLogo" alt="" />
              <h3>{{ rightInfo.creditName }}</h3>
            </div>
            <div class="creditContent" v-html="rightInfo.creditContent"></div>
          </div>
          <div class="bottomBtn" @click="pushCredit(crediArr[crediArrIndex])">
            <div>课程安排</div>
          </div> -->
          <!-- <div class="bottomBtn" @click="pushCredit(crediArr[crediArrIndex])">
            <div>课程安排</div>
          </div> -->
          <button
            class="bottomBtn"
            @click="pushCredit(crediArr[crediArrIndex])"
          >
            课程安排
          </button>
        </div>
      </div>
    </div>
    <!-- 3V1全阶段服务体系 -->
    <div class="page">
      <div class="newTitle"><span>3V1</span> 全阶段服务体系</div>
      <div class="list">
        <div class="newItem p1">
          <div class="fuck">
            <div class="newItemTitleBox">
              <div>01 资深教授</div>
            </div>
            <div class="newItemMsgBox">
              <div>{{ serviceLeftArrDis[0] }}</div>
            </div>
          </div>
          <div @click="checkMore(serviceRightArr[0].name)" class="more">
            <img src="@/assets/img/homePage/new/查看更多.png" alt="" />
          </div>
        </div>
        <div class="newItem p2">
          <div class="fuck">
            <div class="newItemTitleBox">
              <div>02 校园大使</div>
            </div>
            <div class="newItemMsgBox">
              <div>{{ serviceLeftArrDis[1] }}</div>
            </div>
          </div>
          <div @click="checkMore(serviceRightArr[1].name)" class="more">
            <img src="@/assets/img/homePage/new/查看更多.png" alt="" />
          </div>
        </div>
        <div class="newItem p3">
          <div class="fuck">
            <div class="newItemTitleBox">
              <div>03 课程顾问</div>
            </div>
            <div class="newItemMsgBox">
              <div>{{ serviceLeftArrDis[2] }}</div>
            </div>
          </div>
          <div @click="checkMore(serviceRightArr[2].name)" class="more">
            <img src="@/assets/img/homePage/new/查看更多.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="w service">
      <div class="serviceLeft">
        <h2><span>3V1</span>全阶段服务体系</h2>
        <ul>
          <li
            v-for="(item, index) in serviceLeftArr"
            :key="index"
            :class="{ active: serviceLeftArrIndex === index }"
            @click="clickServiceInfo(item, index)"
          >
            
            <div class="serviceName">
              <div class="item">{{ item }}</div>
            </div>
          </li>
        </ul>
        <div class="serviceLeftText">
          <div
            v-for="(item, index) in serviceLeftArrDis"
            :key="index"
            :class="{ active: serviceLeftArrIndex === index }"
          >
            
            {{ item }}
          </div>
        </div>
      </div>
      <ul class="serviceRight">
        <li
          v-for="(item, index) in serviceRightArr"
          :key="index"
          :class="{ active: serviceLeftArrIndex === index }"
          @click="clickServiceInfo(item, index)"
        >
          <img :src="item.imgUrl" alt="" />
          <div class="shade"></div>
          <p>{{ item.name }}</p>
          
          <span @click="checkMore(item.name)"
            >查看更多<i class="el-icon-arrow-right"></i
            ><i class="el-icon-arrow-right"></i
          ></span>
        </li>
      </ul>
    </div> -->

    <!-- 3v3 留学增值规划 -->
    <div class="newPlan">
      <div class="newPlanLeft">
        <transition-group
          name="animate__animated animate__fade"
          mode="out-in"
          enter-active-class="animate__fadeIn"
          leave-active-class="animate__fadeOut"
        >
          <img
            src="@/assets/img/homePage/new/3v3留学增值规划/关键词1.png"
            alt=""
            key="0"
            v-if="planNum == 0"
          />
          <img
            src="@/assets/img/homePage/new/3v3留学增值规划/关键词2.png"
            alt=""
            key="1"
            v-if="planNum == 1"
          />

          <img
            src="@/assets/img/homePage/new/3v3留学增值规划/关键词3.png"
            alt=""
            key="2"
            v-if="planNum == 2"
          />
        </transition-group>
      </div>

      <div class="newPlanRight">
        <div class="newPlanRightBox">
          <el-carousel @change="change" height="654rem">
            <el-carousel-item>
              <div class="newPlanItem">
                <div class="newPlanPic">
                  <img
                    src="../assets/img/homePage/planImg2.bb0eca02.jpg"
                    alt=""
                  />
                </div>
                <div class="newPlanTitle" @click="jump2">了解更多详情</div>
                <div class="newPlanMsg">
                  CSO针对学生需求进行精准评估，专业导师帮助学员共同制定留学申请时间规划。CSO将整合教学资源，为学员提供申研咨询与背景提升服务，打造全面增值渠道，包括前期辅导、科研背景提升、专业择校、文书策划等，帮助学员实现学业进阶。
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="newPlanItem">
                <div class="newPlanPic">
                  <img src="../assets/img/homePage/planImg5.jpg" alt="" />
                </div>
                <div class="newPlanTitle" @click="jump2">了解更多详情</div>
                <div class="newPlanMsg">
                  CSO会根据学员不同需求，免费提供签证办理服务，协助学员进行签证相关材料的整理汇总，并帮助学员进行递交，流程完备高效，定制不同国家类型签证，助力学员快速入学，顺利出国。
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="newPlanItem">
                <div class="newPlanPic">
                  <img src="../assets/img/homePage/planImg6.jpg" alt="" />
                </div>
                <div class="newPlanTitle" @click="jump2">了解更多详情</div>
                <div class="newPlanMsg">
                  CSO聚焦于学员未来职业发展，助力学员留学后背景提升，与500+名企达成合作，为学员提供面试辅导、简历修改、岗位内推等优质求职服务，覆盖金融、投行、咨询、互联网、营销等所有热门行业，给留学生提供深入行业的项目机会，获取项目实操经验以及行业高管指导，提前规划名企求职路径。
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <!-- <div class="plan"> -->
    <!-- <div class="left">
        <el-carousel @change="change" height="770rem">
          <el-carousel-item>
            <div class="pic1 pic"></div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="pic2 pic"></div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="pic3 pic"></div>
          </el-carousel-item>
        </el-carousel>
      </div> -->

    <!-- <div class="right">
        
        <div class="bg">
          <div class="box">
            <div class="title"><span>3v3</span>留学增值规划</div>
            <div class="imgbox">
              <transition-group
                name="animate__animated animate__fade"
                mode="out-in"
                enter-active-class="animate__fadeIn"
                leave-active-class="animate__fadeOut"
              >
                <img
                  src="../assets/img/homePage/planImg2.bb0eca02.jpg"
                  alt=""
                  v-show="planNum == 0"
                  key="0"
                />
                <img
                  src="../assets/img/homePage/planImg5.jpg"
                  alt=""
                  v-show="planNum == 1"
                  key="1"
                />
                <img
                  src="../assets/img/homePage/planImg6.jpg"
                  alt=""
                  v-show="planNum == 2"
                  key="2"
                />
              </transition-group>
            </div>

            <div class="msgTitleBox">
              <div class="position">
                <div class="group">
                  <transition name="titleFadeInOut">
                    <div class="msgTitle" v-show="planNum == 0">
                      1.免费申研服务
                    </div>
                  </transition>
                  <transition name="lineFadeInOut">
                    <div class="line" v-show="planNum == 0"></div>
                  </transition>
                </div>
              </div>
              <div class="position">
                <div class="group">
                  <transition name="titleFadeInOut">
                    <div class="msgTitle" v-show="planNum == 1">
                      2.免费签证服务
                    </div>
                  </transition>
                  <transition name="lineFadeInOut">
                    <div class="line" v-show="planNum == 1"></div>
                  </transition>
                </div>
              </div>
              <div class="position">
                <div class="group">
                  <transition name="titleFadeInOut">
                    <div class="msgTitle" v-show="planNum == 2">
                      3.免费求职服务
                    </div>
                  </transition>
                  <transition name="lineFadeInOut">
                    <div class="line" v-show="planNum == 2"></div>
                  </transition>
                </div>
              </div>
            </div>

            <div class="msgBox">
              <transition-group
                name="animate__animated animate__fade"
                mode="out-in"
                enter-active-class="animate__fadeIn"
                leave-active-class="animate__fadeOut"
              >
                <div class="msg" v-show="planNum == 0" key="0">
                  CSO针对学生需求进行精准评估，专业导师帮助学员共同制定留学申请时间规划。CSO将整合教学资源，为学员提供申研咨询与背景提升服务，打造全面增值渠道，包括前期辅导、科研背景提升、专业择校、文书策划等，帮助学员实现学业进阶。
                </div>

                <div class="msg" v-show="planNum == 1" key="1">
                  CSO会根据学员不同需求，免费提供签证办理服务，协助学员进行签证相关材料的整理汇总，并帮助学员进行递交，流程完备高效，定制不同国家类型签证，助力学员快速入学，顺利出国。
                </div>

                <div class="msg" v-show="planNum == 2" key="2">
                  CSO聚焦于学员未来职业发展，助力学员留学后背景提升，与500+名企达成合作，为学员提供面试辅导、简历修改、岗位内推等优质求职服务，覆盖金融、投行、咨询、互联网、营销等所有热门行业，给留学生提供深入行业的项目机会，获取项目实操经验以及行业高管指导，提前规划名企求职路径。
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </div> -->

    <!-- <el-carousel height="770rem">
        这是最早的版本
        <el-carousel-item v-for="item in 4" :key="item">
          <div class="planBox">
            <div class="planLeft">
              <img src="../assets/img/homePage/planImg1.png" alt="" />
            </div>
            <div class="planRight">
              <div class="planRightContent">
                <div class="planRightContentBox">
                  <h2><span>3v3</span>留学增值规划</h2>
                  <img src="../assets/img/homePage/planImg3.png" alt="" />
                  <div>
                    <span>免费申研服务</span>
                    <div></div>
                  </div>
                  <span class="planRightContenTtext"
                    >CSO针对学生需求进行精准评估，专业导师帮助学员共同制定留学申请时间规划。CSO将整合教学资源，为学员提供申研咨询与背景提升服务，打造全面增值渠道，包括前期辅导、科研背景提升、专业择校、文书策划等，帮助学员实现学业进阶。</span
                  >
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel> -->
    <!-- </div> -->

    <!-- CSO学员来自 -->
    <div class="jiaoshou">
      <div class="jiaoshouTitle">cso学员来自</div>
      <div class="listBox">
        <div v-for="(item, index) in 2" :key="index" class="out">
          <div v-for="(item2, index2) in professorInfo.picture" :key="index2">
            <img :src="item2.pictureUrl" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="stuInfo">
      <div class="stuBox">
        <el-carousel arrow="always" height="500rem">
          <el-carousel-item
            class="stuInfoItem"
            v-for="(item, index) in stuInfo"
            :key="index"
          >
            <!-- <h3 class="small">{{ item }}</h3> -->
            <img :src="item.pictureUrl" alt="" />
            <div class="content Btitle" v-html="item.pictureBtitle"></div>
            <div class="content Stitle" v-html="item.pictureStitle"></div>
            <div class="content" v-html="item.pictureContent"></div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- CSO学员来自 -->
    <!-- <div class="w camp">
      <div class="campHeader">
        <h2>CSO学员来自</h2>
        <div class="campHeaderRight">
          <el-carousel height="448rem" :interval="2500">
            <el-carousel-item v-for="(item, index) in campImg" :key="index">
              <img :src="item.pictureUrl" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <el-carousel
        height="320rem"
        :interval="2500"
        arrow="always"
        trigger="click"
      >
        <el-carousel-item v-for="(item, index) in campArr" :key="index">
          <div class="carouselBox">
            <div
              class="carouselInfo"
              v-for="(item2, index2) in item.campInfo"
              :key="index2"
            >
              <img :src="item2.pictureUrl" alt="" />
              <div class="infoRight">
                <p class="infoName">{{ item2.pictureBtitle }}</p>
                <div class="schoolInfo">
                  <div v-html="item2.pictureContent"></div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div> -->

    <!-- 选择CSO的理由 -->
    <div class="select">
      <div class="selectTitle">
        <div class="big">选择CSO的理由</div>
        <div class="small"><span>WHY</span> CHOOSE CSO</div>
      </div>
      <div class="selectBox">
        <div class="yellow" @click="jump('/hotCourse')">
          <div>01</div>
          <div>定制课程，加速毕业</div>
          <div>
            CSO将为学员选择定制课程，课程符合海外课程标准，涵盖线上、线下等模式，
            保障个性化服务。在导师与助教的针对性授课下，学员能够轻松顺利地完成学业。
          </div>
        </div>
        <div class="blue" @click="jump('/dashi')">
          <div>02</div>
          <div>学长学姐陪伴式教学</div>
          <div>
            拥有丰富海外留学经验的学长学姐充分了解学生需求不仅能协助留学生顺利完成学业，也能帮助留学生充分适应国外生活，拥有自己的社交人脉。
          </div>
        </div>
        <div class="selectPic p1">
          <img
            class="selectImg"
            src="../assets/img/homePage/reasonImg1.png"
            alt=""
          />
        </div>

        <div class="selectPic p2">
          <img
            class="selectImg"
            src="../assets/img/homePage/reasonImg3.png"
            alt=""
          />
        </div>
        <div class="yellow" @click="jump('/turnGrade')">
          <div>03</div>
          <div>节省学生费用</div>
          <div>
            CSO学分课程花费少，为学员减少在国外留学的高昂生活成本，学员通过教研团队的帮助，
            也能更快更好地修得学分，同时节省留学时间与资金。
          </div>
        </div>
        <div class="blue" @click="jump('/about')">
          <div>04</div>
          <div>参与精彩活动</div>
          <div>
            课余闲暇CSO组织策划各类新潮的娱乐活动，如密室、剧本杀、外出旅游等娱乐活动，消除学业繁忙的疲倦，
            学员能够结交志同道合的小伙伴，度过充实愉快假期。
          </div>
        </div>

        <div class="yellow" @click="jump('/dashi')">
          <div>05</div>
          <div>结识精英人脉</div>
          <div>
            留学生参与CSO举办的校园大使实习项目，结识行业精英，
            与全国优秀的留学生进行学术研讨；通过线下活动促进学员交流，
            让学员充分扩张自己的人脉社交圈。
          </div>
        </div>
        <div class="blue" @click="jump('/dashi')">
          <div>06</div>
          <div>留学拓展提升</div>
          <div>
            CSO熟知国外高等教育模式的同时，了解跨境教育相关政策，在保障学业畅通的同时，
            拓展学员多样化的提升路径，力图为学员进行全方位的指导规划，引导他们走向多元化、全面的发展道路。
          </div>
        </div>
        <div class="selectPic p3">
          <img
            class="selectImg"
            src="../assets/img/homePage/reasonImg2.png"
            alt=""
          />
        </div>
      </div>

      <div class="video">
        <div class="videoBox">
          <video
            controls
            preload="auto"
            id="videoId"
            ref="refVideo"
            :src="videoUrl"
            :poster="videoImg"
          ></video>
        </div>
      </div>
    </div>

    <!-- 选择CSO的理由 -->
    <!-- <div class="w reason">
      <div class="reason_1">
        <p>选择CSO的理由</p>
        <p><span>WHY</span> CHOOSE CSO</p>
        <p></p>
      </div>
      <div class="reason_2">
        <div class="reason_2Box">
          <div @click="jump('/hotCourse')">
            <h3><span>01</span></h3>
            <p>定制课程，加速毕业</p>
            <span
              >CSO将为学员选择定制课程，课程符合海外课程标准，涵盖线上、线下等模式，
              保障个性化服务。在导师与助教的针对性授课下，学员能够轻松顺利地完成学业。</span
            >
          </div>
          <div @click="jump('/dashi')">
            <h3><span>02</span></h3>
            <p>学长学姐陪伴式教学</p>
            <span>
              拥有丰富海外留学经验的学长学姐充分了解学生需求不仅能协助留学生顺利完成学业，也能帮助留学生充分适应国外生活，拥有自己的社交人脉。</span
            >
          </div>
        </div>
      </div>
      <div class="reason_2Img">
        <img src="../assets/img/homePage/reasonImg1.jpg" alt="" />
      </div>
      <div class="reason_3">
        <div class="reason_3Img">
          <img src="../assets/img/homePage/reasonImg3.jpg" alt="" />
        </div>
        <div class="reason_3Box">
          <div @click="jump('/turnGrade')">
            <h3><span>03</span></h3>
            <p>节省学生费用</p>
            <span
              >CSO学分课程花费少，为学员减少在国外留学的高昂生活成本，学员通过教研团队的帮助，
              也能更快更好地修得学分，同时节省留学时间与资金。</span
            >
          </div>
          <div @click="jump('/about')">
            <h3><span>04</span></h3>
            <p>参与精彩活动</p>
            <span>
              课余闲暇CSO组织策划各类新潮的娱乐活动，如密室、剧本杀、外出旅游等娱乐活动，消除学业繁忙的疲倦，
              学员能够结交志同道合的小伙伴，度过充实愉快假期。</span
            >
          </div>
        </div>
      </div>
      <div class="reason_4">
        <div class="reason_4Box">
          <div class="shadow" @click="jump('/dashi')">
            <h3><span>05</span></h3>
            <p>结识精英人脉</p>
            <span
              >留学生参与CSO举办的校园大使实习项目，结识行业精英，
              与全国优秀的留学生进行学术研讨；通过线下活动促进学员交流，
              让学员充分扩张自己的人脉社交圈。</span
            >
          </div>
          <div class="shadow" @click="jump('/dashi')">
            <h3><span>06</span></h3>
            <p>留学拓展提升</p>
            <span
              >CSO熟知国外高等教育模式的同时，了解跨境教育相关政策，在保障学业畅通的同时，拓展学员多样化的提升路径，力图为学员进行全方位的指导规划，引导他们走向多元化、全面的发展道路。</span
            >
          </div>
          <div class="reason_4Img">
            <img src="../assets/img/homePage/reasonImg2.jpg" alt="" />
          </div>
        </div>
      </div>
      
    </div> -->
    <!-- 出国留学 -->
    <!-- <div class="come">
      <div class="comeBox w">
        
        <video
          controls
          preload="auto"
          id="videoId"
          ref="refVideo"
          :src="videoUrl"
          :poster="videoImg"
        ></video>
      </div>
      
    </div> -->
    <!-- 更多资讯 -->
    <div class="information">
      <div class="informationHeader">更多资讯</div>
      <div class="informationInfo w">
        <ul class="infoLeft">
          <li
            v-for="(item, index) in informationArr"
            :key="index"
            :class="{ active: informationArrIndex === index }"
            @mouseenter="clickInformation(item, index)"
            @click="clickNews(item, index)"
          >
            <div class="infoLeftBox">
              <div class="infoHeader">
                <div class="infoHeaderLeft">
                  <span>{{ item.newsPublishTime | dataformat }}</span>
                </div>
                <div class="infoHeaderRight">
                  MORE <i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div class="infoContent">
                <p>{{ item.newsTitle }}</p>
                <div v-html="item.newsDescribe"></div>
              </div>
            </div>
          </li>
        </ul>
        <div class="infoRight">
          <transition-group
            name="animate__animated animate__fade"
            mode="out-in"
            enter-active-class="animate__fadeIn"
            leave-active-class="animate__fadeOut"
          >
            <img
              v-if="informationArrIndex == 0"
              :src="informationUrl"
              alt=""
              key="0"
            />
            <img
              v-if="informationArrIndex == 1"
              :src="informationUrl"
              alt=""
              key="1"
            />
            <img
              v-if="informationArrIndex == 2"
              :src="informationUrl"
              alt=""
              key="2"
            />
            <img
              v-if="informationArrIndex == 3"
              :src="informationUrl"
              alt=""
    tool,
              key="3"
            />
          </transition-group>
        </div>
      </div>
      <div class="moreInfo">
        <div class="moreInfoHeader">
          <div class="lookMore" @click="jumpToNews">查看更多</div>
        </div>
        <botIntroduce></botIntroduce>
      </div>
    </div>
    <!-- 底部信息 -->
    <div class="homeBottom">
      Copyright © 2021-2022 CSO 鄂ICP备2021009136号-1
    </div>
    <!-- 左侧边工具栏 -->
    <toolLeft></toolLeft>
    <!-- 侧边工具栏 -->
    <tool></tool>
  </div>
</template>

<script>
import headerNav from "../components/headerNav.vue";
import tool from "../components/tool.vue";
import toolLeft from "../components/toolLeft.vue";
import botIntroduce from "../components/botIntroduce.vue";
import "animate.css";
import { mapMutations, mapState } from "vuex";
import { aboutcso } from "@/api/api";
import { professor } from "@/api/api";
export default {
  data() {
    return {
      planNum: 0,
      // 轮播页面显示文字
      carouselList: [],
      // 简介右侧轮播数据
      carouselRightArr: [
        {
          topTips: "真实在校学长学姐提供学习生活帮助",
          botTips: "300+",
        },
        {
          topTips: "顾问、学服、学姐全程陪护，省心转学分",
          botTips: "3v1",
        },
        {
          topTips: "与世界各地一流高校达成学分互认体系",
          botTips: "500+",
        },
        {
          topTips: "工商文理艺等400余门课程+私人定制",
          botTips: "400+",
        },
      ],
      // 学分项目数据
      crediArr: [],
      crediArrIndex: 0,
      rightInfo: {},
      crediBg: "",
      // 3V1全阶段服务体系左边数据
      serviceLeftArr: ["资深教授", "校园大使", "顾问"],
      serviceLeftArrDis: [
        "CSO学分项目的教授来自世界顶级名校，均拥有10年以上教学经验。授课认真负责，保障权威教学资质。CSO将根据每位学员情况，配备有对应课程辅导经验的的教授，以全学科领域专业度帮助学员轻松修读学分课程。",
        "校园大使是CSO的国际精英团队的领头人，拥有丰富的海外研学经验。负责为留学生答疑解惑，规划课程，组织丰富的课余活动，帮助学员快速适应课程学习，顺利完成学业，同时拓展社交圈，结识更多志同道合的伙伴。",
        "CSO为每个学员配备专业资深的课程顾问，进行一对一学业规划指导、选课和审课，定制个性化学分课程方案，协助指导学员自主学习，联系任课导师，帮助学员合理安排学习时间。",
      ],
      serviceLeftArrIndex: 0,
      // 3V1全阶段服务体系右边数据
      serviceRightArr: [
        {
          name: "01.资深教授",
          imgUrl: require("../assets/img/homePage/serviceImg1.png"),
        },
        {
          name: "02.校园大使",
          imgUrl: require("../assets/img/homePage/serviceImg2.png"),
        },
        {
          name: "03.课程顾问",
          imgUrl: require("../assets/img/homePage/serviceImg3.png"),
        },
      ],
      // CSO集中营图片
      campImg: [],
      // CSO学员来自数据
      campArr: [],
      // 视频地址
      videoUrl: "",
      // 视频封面:
      videoImg: "",
      // 视频弹窗
      dialogVideo: false,
      // 更多资讯数据
      informationArr: [],
      informationArrIndex: 0,
      informationUrl: "",
      caseList: [],
      professorInfo: "",
      stuInfo: "",
    };
  },
  components: {
    headerNav,
    tool,
    toolLeft,
    botIntroduce,
  },
  methods: {
    ...mapMutations(["initCrediArr"]),
    jump(url) {
      this.$router.push({
        path: url,
        query: { activeIdx: 2 },
      });
    },
    jump2() {
      window.open("https://csoadvance.com");
    },
    jumpToNews() {
      // this.$router.push("/about");
      this.$router.push({
        path: "/about",
        query: { activeIdx: 2 },
      });
    },
    change(num) {
      this.planNum = num;
    },
    // 3V1全阶段查看更多
    checkMore(name) {
      // console.log(name, "name");
      if (name == "01.资深教授") {
        this.$router.push("/jiaoshou");
      } else if (name == "02.校园大使") {
        this.$router.push("/dashi");
      } else if (name == "03.课程顾问") {
        this.$router.push("/guwen");
      }
    },
    // 获取数据
    initData() {
      var _this = this;
      _this.$http.post("/web", {}).then(function (resp) {
        let res = resp.data;
        _this.initCrediArr(res.data.credit);
        if (res.code == 200) {
          console.log(res.data, "homepage");
          _this.carouselList = res.data.banner;
          _this.crediArr = res.data.credit;
          _this.rightInfo = res.data.credit[0];
          _this.crediBg = res.data.credit[0].creditBgimg;
          _this.campImg = res.data.camp;
          _this.informationArr = res.data.news;
          _this.informationUrl = res.data.news[0].newsCoverImg;
          _this.videoUrl = res.data.video[0].videoUrl;
          _this.videoImg = res.data.video[0].videoBgimg;
          let arr = res.data.student;
          let result = [];
          for (let i = 0, len = arr.length; i < len; i += 2) {
            let resultItem = {
              campInfo: arr.slice(i, i + 2),
            };
            result.push(resultItem);
          }
          _this.campArr = result;
          _this.stuInfo = res.data.student;
        }
      });
    },
    pushCredit(arr) {
      // console.log(arr, "arr");
      this.initCrediArr(arr);
      this.$router.push({
        path: "/credit",
        query: { creditId: this.crediArr[this.crediArrIndex].id },
      });
    },
    // 点击学分项目左侧学校
    clickSchool(item, index) {
      this.crediArrIndex = index;
      this.rightInfo = item;
      this.crediBg = item.creditBgimg;
    },
    // 点击服务左右信息进行切换
    clickServiceInfo(item, index) {
      this.serviceLeftArrIndex = index;
    },
    // 点击资讯信息
    clickInformation(item, index) {
      this.informationArrIndex = index;
      this.informationUrl = item.newsCoverImg;
    },
    clickNews(item, index) {
      console.log(item);
      // this.newsImg = item.newsCoverImg;
      // this.newsIndex = index;
      this.$router
        .push({
          path: "newsDetails",
          query: {
            newsId: JSON.stringify(item.id),
          },
        })
        .catch(() => {});
    },
    // 点击视频中心播放
    playViedo() {
      let _this = this;
      _this.dialogVideo = true;
    },
    // 关闭视频弹窗
    videoClose() {
      let _this = this;
      _this.dialogVideo = false;
    },
    getaboutcso() {
      aboutcso().then((res) => {
        console.log(res.data.case, "getaboutcso");
        // this.chengjiuList = res.data.achievement;
        // this.prize = res.data.prize;
        this.caseList = res.data.case;
        // console.log(this.caseList, "this.caseList");
        // console.log(this.aboutcsoInfo, "aboutcsoInfo");
        // this.aboutcsoList = res.data.aboutcso;
        // console.log("999999999999999999999999999999999999999");
      });
    },
    getprofessor() {
      professor().then((res) => {
        this.professorInfo = res.data;
        console.log(this.professorInfo, "professorInfo");
      });
    },
  },

  mounted() {
    this.initData();
    // this.initCrediArr();
    this.getaboutcso();
    this.getprofessor();
  },
};
</script>
<style lang="scss" scoped>
.homePage {
  width: 100%;
  color: #373737;
  overflow-x: auto;
  z-index: 5;
  .page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 10rem;
    .newTitle {
      font-size: 48rem;
      font-weight: bold;
      span {
        color: #2864b4;
      }
    }
    .list {
      display: flex;
      justify-content: space-between;
      margin-top: 10rem;
      .p1 {
        background-image: url(@/assets/img/homePage/new/01.png);
        background-repeat: no-repeat;
        background-size: cover;
      }
      .p2 {
        background-image: url(@/assets/img/homePage/new/02.png);
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 10rem;
        margin-right: 10rem;
      }
      .p3 {
        background-image: url(@/assets/img/homePage/new/04.png);
        background-repeat: no-repeat;
        background-size: cover;
      }
      .newItem {
        width: 630rem;
        height: 794rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;
        position: relative;
        .fuck {
          transition: 0.5s;
          transform: translateY(140rem);
          &:hover {
            transform: translateY(0);
          }
          .newItemTitleBox {
            display: flex;
            align-items: center;
            width: 100%;
            height: 140rem;
            background-image: url(@/assets/img/homePage/new/蓝条1.png);
            background-repeat: no-repeat;
            background-size: cover;
            box-sizing: border-box;
            padding: 30rem;
            div {
              font-size: 30rem;
              font-weight: bold;
              color: #fff;
            }
          }
          .newItemMsgBox {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 140rem;
            background-color: #2864b4;
            box-sizing: border-box;
            padding: 30rem;
            padding-top: 0;
            // transition: 0.5s;
            // transform: translateY(100%);
            div {
              font-size: 20rem;
              color: #fff;
            }
          }
        }
        .more {
          position: absolute;
          top: 20rem;
          right: 20rem;
          cursor: pointer;
        }
        // margin: 10rem;
      }
    }
  }
  .jiaoshou {
    width: 100%;
    height: 406rem;
    background-image: url(@/assets/img/homePage/new/蓝条3.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 40rem;
    // height: 200rem;
    // background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .jiaoshouTitle {
      font-size: 48rem;
      font-weight: bold;
      color: #fff;
    }
    .listBox {
      display: flex;
      width: 70%;
      overflow: hidden;
      .out {
        display: flex;
        flex-wrap: nowrap;
        // margin-top: 76rem;
        // overflow: hidden;
        animation: 30s scrollRight linear infinite;
        // animation-fill-mode: both;
        div {
          margin: 30rem;
          img {
            width: 195rem;
            height: 195rem;
            object-fit: cover;
          }
        }
      }
      @keyframes scrollRight {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-100%);
        }
      }
    }
  }
  .stuInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .stuBox {
      width: 70%;
      // height: 100%;
      // background-color: #2c3e50;
      .stuInfoItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 195rem;
          height: 195rem;
          object-fit: cover;
          border-radius: 50%;
        }
        .content {
          margin-top: 20rem;
          width: 70%;
          display: flex;
          flex-direction: column;
          align-items: center;
          ::v-deep {
            font-size: 20rem;
          }
          text-align: center;
        }
        .Btitle,
        .Stitle {
          font-weight: bold;
          color: #2864b4;
        }
      }
    }
  }
  .select {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 250rem;
    background-image: url(@/assets/img/homePage/new/背景.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    .selectTitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      .big {
        font-size: 48rem;
        font-weight: bold;
      }
      .small {
        font-size: 20rem;
      }
    }
    .selectBox {
      // width: 70%;
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(4, 1fr);
      margin-top: 20rem;
      margin-bottom: 30rem;
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 345rem;
        height: 290rem;
        box-sizing: border-box;
      }
      .yellow {
        background-color: #ffaf19;
      }
      .blue {
        background-color: #2864b4;
      }
      .yellow,
      .blue {
        color: #fff;
        padding: 30rem;
        cursor: pointer;
        div:nth-child(1) {
          font-size: 60rem;
          font-weight: bold;
        }
        div:nth-child(2) {
          // margin: 20rem;
          margin-bottom: 15rem;
          font-size: 20rem;
          font-weight: bold;
        }
        transition: 0.5s;
        &:hover {
          transform: scale(1.08);
          z-index: 20;
        }
      }
      .selectPic {
        width: 690rem;
        height: 290rem;
        .selectImg {
          width: 100%;
          height: 100%;
          // width: 690rem;
          // height: 290rem;
          object-fit: cover;
        }
      }
      .p1 {
        grid-row: 1 / span 1;
        grid-column: 3 / span 2;
      }
      .p2 {
        grid-row: 2 / span 1;
        grid-column: 1 / span 2;
      }
      .p3 {
        grid-row: 3 / span 1;
        grid-column: 3 / span 2;
      }
    }
    .video {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 1380rem;
      // padding: 20rem;
      // background-color: red;
      background-color: #fff;
      .videoBox {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20rem;
        box-sizing: border-box;
        video {
          width: 100%;
          // margin: 20rem;
          object-fit: contain;
        }
      }
    }
  }
  .newPlan {
    display: flex;
    width: 100%;
    height: 769rem;
    margin-top: 100rem;
    margin-bottom: 150rem;
    background-image: url(@/assets/img/homePage/new/3v3留学增值规划/板块.png);
    background-repeat: no-repeat;
    background-size: cover;
    .newPlanLeft {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      position: relative;
      width: 504rem;
      grid-template: 100%;
      // background-color: #2980b9;
      // opacity: 0.5;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 255rem;
        height: 557rem;
        object-fit: cover;
        margin-left: 50rem;
      }
    }
    .newPlanRight {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // flex: 1;
      width: 1298rem;
      height: 100%;

      // background-color: #8e44ad;
      // opacity: 0.5;
      .newPlanRightBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 85%;
        // background-color: #2980b9;
        // opacity: 0.5;
        .el-carousel {
          .newPlanItem {
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: center;
            margin-top: 100rem;
            .newPlanPic {
              width: 1110rem;
              height: 248rem;
              img {
                width: 100%;
                height: 100%;
                border-radius: 10rem;
                object-fit: cover;
              }
            }
            .newPlanTitle {
              margin-top: 20rem;
              padding: 5rem;
              padding-left: 15rem;
              padding-right: 15rem;
              letter-spacing: 5rem;
              border-radius: 33rem;
              font-size: 28rem;
              font-weight: bold;
              color: #fff;
              cursor: pointer;
              background-color: #2864b4;
              transition: 0.5s;
              &:hover {
                box-shadow: 0 2rem 12rem 0 rgba(0, 0, 0, 0.5);
              }
            }
            .newPlanMsg {
              margin-top: 20rem;
              width: 1110rem;
              font-size: 22rem;
              // letter-spacing: 1rem;

              line-height: 40rem;
              text-align: center;
            }
          }
        }
        // .newPlanItem {
        //   margin-top: 100rem;
        //   // height: 400rem;
        //   background-color: #8e44ad;
        // }
      }
    }
  }
}
// .showR {
//   position: absolute;
//   top: 0;
//   right: 0;
//   opacity: 0;
//   transition: 0.5s;
//   width: 270rem;
//   height: 1080rem;
//   background: linear-gradient(90deg, rgba(55, 55, 55, 0), #373737);
// }
/* 轮播图样式设置 */
.homePage .head ::v-deep .el-carousel__button {
  width: 18rem;
  height: 18rem;
  border-radius: 50%;
}
.homePage .head ::v-deep .el-carousel__arrow {
  font-size: 46rem;
  background: transparent;
}
.homePage .head ::v-deep .el-carousel__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homePage .head ::v-deep .el-carousel__arrow i {
  // display: none;
}
// .homePage .head ::v-deep .el-carousel__arrow:hover i {
//   display: block;
// }

.homePage .carouselText {
  position: absolute;
  // top: 341rem;
  // left: 271rem;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  z-index: 5;
}
.homePage .carouselText h2 {
  font-size: 100rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 8rem;
}
.homePage .carouselText .line {
  width: 471rem;
  height: 5rem;
  background: #ffffff;
  margin-top: 45rem;
  margin-bottom: 46rem;
}
.homePage .carouselText .pictureContent {
  font-size: 28rem;
  color: #fff;
  font-family: Source Han Sans CN;
  font-weight: 400;
}
/* 简介样式 */
.homePage .introduce {
  padding-top: 116rem;
  padding-bottom: 20rem;
  border-bottom: 1rem solid #dcdcdc;
  margin-bottom: 70rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  min-width: 1400rem;
}
.homePage .introduce .boxLeft {
  width: 800rem;
  font-size: 16rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #373737;
  line-height: 36rem;
}
.homePage .introduce .boxRight {
  margin-left: 122rem;
  width: 400rem;
}
.homePage .introduce .boxRight span {
  font-size: 16rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #373737;
}
.homePage .introduce .boxRight p {
  font-size: 136rem;
}
.homePage .introduce .boxRight ::v-deep .el-carousel__indicators--horizontal {
  display: none;
}
.homePage .introduce .boxRight ::v-deep .el-carousel__arrow--left {
  left: 70%;
}
.homePage .introduce .boxRight ::v-deep .el-carousel__arrow {
  top: 90%;
}
/* 学分项目样式 */
.homePage .crediProject {
  width: 100%;
  height: 780rem;
  margin-bottom: 94rem;
  position: relative;
}
.homePage .crediBg {
  width: 100%;
  height: 100%;
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  object-fit: cover;
  // transition: 0.3s;
}
.homePage .crediBox {
  width: 100%;
  height: 100%;
  // display: flex;
  z-index: 5;
  // position: relative;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.homePage .crediProject .crediLeft {
  position: absolute;
  left: 227rem;
  z-index: 6;
  top: 50%;
  transform: translateY(-50%);
  // top: 0;
  // flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 100%;
  // height: 100%;
  height: 780rem;
  box-sizing: border-box;
  /* text-align: center; */
  padding-top: 75rem;
  padding-bottom: 50rem;
  padding-left: 45px;
  padding-right: 45px;
  list-style: none !important;
  // background-color: red;

  // overflow: scroll;
  // ::-webkit-scrollbar-track {
  //   background: #eee;
  //   border-radius: 10rem;
  // }
  // ::-webkit-scrollbar-thumb {
  //   border-radius: 10rem;
  //   background: #ffaf19;
  // }
  width: 444px;
  height: 661px;
  background: #2864b4;
  box-sizing: border-box;

  .underLine {
    width: 350px;
    height: 1px;
    background: #ffffff;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  ul {
    overflow: auto;
    width: 100%;
    // height: 550rem;
    // background-color: #e74c3c;
  }
}
// .homePage .crediProject .crediLeft ::-webkit-scrollbar-thumb {
//   border-radius: 10rem;
//   background: #ffaf19;
// }
.homePage .crediProject .crediLeft h2 {
  margin: 0 auto;
  color: #fff;
  font-size: 48rem;
  font-weight: bold;
  // margin-bottom: 100rem;
}
.homePage .crediProject .crediLeft li {
  margin-bottom: 35rem;
  font-size: 24rem;
  color: #fff;
  /* display: flex; */
  /* justify-content: center; */
  /* justify-content: flex-end; */
  /* position: relative; */
  width: 100%;
  cursor: pointer;
  /* background-color: lightblue; */
  transition: 0.5s;
  // background-color: red;
}
/* .homePage .crediProject .crediLeft li .linebox {
  display: flex;
  justify-content: flex-end;
  width: 40%;
  height: 20rem;
  
} */
.homePage .crediProject .crediLeft li .creditName {
  /* transition: 0.5s; */
  /* transform: translateX(-50%); */
  /* display: flex;
  justify-content: center; */
  position: relative;
  // width: 100%;
  overflow: hidden;
  // margin-left: 130rem;
  /* overflow-x: hidden; */
  /* display: flex; */
  /* justify-content: center; */
  /* background-color: lightblue; */
  // background-color: red;
}
.homePage .crediProject .crediLeft li .creditName .item {
  /* margin-left: 100rem; */
  /* width: 100%; */
  /* background-color: red; */
  /* position: absolute;
  left: 50%;
  transform: translate(-50%); */
  transition: 0.5s;
}
.homePage .crediProject .crediLeft li .creditName .item::before {
  position: absolute;
  left: -60rem;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 45rem;
  height: 3rem;
  border-radius: 1.5rem;
  background: #ffaf19;
}
.homePage .crediProject .crediLeft li .creditName:hover {
  color: #ffaf19;
  /* animation-name: yellow;
  animation-duration: 0.5s;
  animation-fill-mode: forwards; */
}

/* @keyframes yellow {
  to {
    color: #ffaf19;
  }
}
@keyframes move {
  to {
    transform: translateX(10rem);
  }
} */
/* .homePage .crediProject .crediLeft li span {
  width: 45rem;
  height: 3rem;
  background: #ffaf19;
  display: none;
  line-height: 34rem;
  margin-top: 14rem;
  margin-right: 20rem;
} */
.homePage .crediProject .crediLeft .active .creditName .item {
  /* animation-name: move;
  animation-duration: 0.5s;
  animation-fill-mode: forwards; */
  transform: translateX(60rem);
  color: #ffaf19;
}
/* .homePage .crediProject .crediLeft .active .creditName {
  animation-name: school;
  animation-duration: 0.3s;
}
@keyframes school {
  from {
    transform: translateX(0rem);
  }
  to {
    transform: translateX(45rem);
  }
} */
/* .homePage .crediProject .crediLeft .active span {
  display: block;
} */

// .slide-fade-enter-active {
//   transition: all 0.3s ease;
// }
// .slide-fade-leave-active {
//   transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
// }
// .slide-fade-enter,
// .slide-fade-leave-to {
//   transform: translateX(-10rem);
//   opacity: 0;
// }

// 淡入淡出
// .fadeInOut-enter-active,
// .fadeInOut-leave-active {
//   transition: opacity 0.5s ease;
// }
// .fadeInOut-enter,
// .fadeInOut-leave-to {
//   opacity: 0;
// }

.homePage .crediProject .crediRight {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  // flex: 2;
  width: 1250rem;
  // margin-right: 0;
  background: rgba(0, 0, 0, 0.66);
  .outBox {
    position: relative;
    // height: 100%;
    // height: 70rem;
    // overflow: auto;
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.9s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
  }
}
.homePage .crediProject .crediRight .crediRightBox {
  position: absolute;
  top: 50rem;
  left: 50%;
  // transform: translate(-50%, -50%);
  transform: translateX(-50%);
  width: 800rem;
  // height: 80%;
  height: 620rem;
  // margin: 65rem auto 0;
  padding-right: 100rem;
  overflow: auto;
  // padding-right: 50rem;
  // background-color: #f1c40f;
  ::v-deep {
    .creditContent {
      font-size: 16rem;
      line-height: 32rem;
      color: #ffffff;
    }
  }
}
/* 设置滚动条的样式 */
.homePage .crediProject .crediRight .outBox ::-webkit-scrollbar {
  width: 5rem;
}
.homePage .crediProject .crediBox ::-webkit-scrollbar {
  width: 5rem;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 10rem;
}
/* 滚动条滑块 */
.homePage .crediProject .crediRight ::-webkit-scrollbar-thumb {
  border-radius: 10rem;
  background: #ffaf19;
}
.homePage .crediProject .crediBox ::-webkit-scrollbar-thumb {
  border-radius: 10rem;
  background: #ffaf19;
}
.homePage .crediProject .crediRight .crediRightBox .crediHeaderInfo {
  text-align: center;
}
.homePage .crediProject .crediRight .crediRightBox .crediHeaderInfo img {
  width: 168rem;
  height: 168rem;
  margin: 27rem auto;
  text-align: center;
}
.homePage .crediProject .crediRight .crediRightBox .crediHeaderInfo h3 {
  font-size: 24rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  line-height: 40rem;
  letter-spacing: 8rem;
  margin-bottom: 36rem;
}
.homePage .crediProject .crediRight .crediRightBox .crediHeaderInfo p {
  white-space: pre-wrap; /*这是重点。文本换行*/
  font-size: 20rem;
  color: #ffaf19;
  font-weight: bold;
}
.homePage .crediProject .crediRight .crediBottomInfo {
  color: #fff;
  font-size: 16rem;
  margin-top: 31rem;
}
.homePage .crediProject .crediRight .crediBottomInfo div:first-child {
  line-height: 32rem;
}
.homePage .crediProject .crediRight .crediBottomInfo div:last-child {
  margin-top: 36rem;
  white-space: pre-wrap; /*这是重点。文本换行*/
  line-height: 32rem;
}

.homePage .crediProject .crediRight .bottomBtn {
  position: absolute;
  bottom: 50rem;
  right: 200rem;
  width: 200rem;
  height: 50rem;
  border-radius: 25rem;
  // text-align: center;
  // line-height: 50rem;
  border: 2rem solid #bbb;
  color: #bbb;
  letter-spacing: 4rem;
  font-size: 20rem;
  // margin: 21rem auto;
  background: transparent;
}
.homePage .crediProject .crediRight .bottomBtn div:hover {
  border: 2rem solid #fff;
  color: #fff;
  cursor: pointer;
}
/* 3V1全阶段服务体系 */
.homePage .service {
  height: 786rem;
  margin-bottom: 45rem;
  display: flex;
}
.homePage .service .serviceLeft {
  display: flex;
  flex-direction: column;
  margin: 80rem 41rem 0 58rem;
  max-width: 380rem;
}
.homePage .service .serviceLeft h2 {
  font-size: 36rem;
  font-weight: bold;
}
.homePage .service .serviceLeft h2 span {
  color: #2864b4;
  margin-right: 10rem;
}
.homePage .service .serviceLeft ul {
  margin-top: 200rem;
  font-size: 24rem;
  border-bottom: 1rem solid #b5b5b5;
}
.homePage .service .serviceLeft ul li {
  margin-bottom: 28rem;
  display: flex;
  cursor: pointer;
}
.homePage .service .serviceLeft ul li .linebox {
  display: flex;
  justify-content: flex-end;
  width: 80rem;
  height: 10rem;
  margin-left: -80rem;
}
.homePage .service .serviceLeft ul li .serviceName {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.homePage .service .serviceLeft ul li .serviceName .item {
  transition: 0.5s;
}
.homePage .service .serviceLeft ul li .serviceName .item::before {
  position: absolute;
  left: -60rem;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 45rem;
  height: 3rem;
  border-radius: 1.5rem;
  background: #2864b4;
}
.homePage .service .serviceLeft ul li .serviceName:hover {
  color: #2864b4;
}
.homePage .service .serviceLeft ul li span {
  width: 45rem;
  height: 3rem;
  background: #2864b4;
  /* display: none; */
  line-height: 34rem;
  margin-top: 14rem;
  margin-right: 16rem;
}
.homePage .service .serviceLeft ul .active {
  color: #2864b4;
}
/* .homePage .service .serviceLeft ul .active span {
  display: block;
} */
.homePage .service .serviceLeft ul .active .serviceName .item {
  transform: translateX(60rem);
}
.homePage .service .serviceLeft .serviceLeftText {
  position: relative;
  width: 380rem;
  height: 124rem;
  margin-top: 26rem;
  color: #777;
  line-height: 36rem;
  // background-color: red;
  // border: 1px solid red;
  div {
    position: absolute;
    top: 50;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    opacity: 0;
    transition: 0.5s;
    font-size: 16rem;
  }
  .active {
    opacity: 1;
  }
}
.homePage .service .serviceRight {
  display: flex;
}
.homePage .service .serviceRight li {
  width: 300rem;
  height: 720rem;
  margin-right: 5rem;
  position: relative;
  cursor: pointer;
}
.homePage .service .serviceRight li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // cursor: pointer;
}
.homePage .service .serviceRight li .shade {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  transition: 0.5s;
}
.homePage .service .serviceRight li .shade:hover {
  opacity: 0;
}
/* .homePage .service .serviceRight li:hover .shade {
  display: none;
} */
.homePage .service .serviceRight .active .shade {
  opacity: 0;
}
.homePage .service .serviceRight li p {
  position: absolute;
  top: 53rem;
  left: 33rem;
  font-size: 24rem;
  font-weight: bold;
  color: #fff;
}
.homePage .service .serviceRight li span {
  position: absolute;
  bottom: 40rem;
  right: 34rem;
  color: rgb(187, 187, 187);
  font-size: 20rem;
  margin-right: 5rem;
  transition: 0.5s;

  /* visibility: hidden; */
  opacity: 0;
  z-index: 2;
}
.homePage .service .serviceRight li:hover span {
  /* visibility: visible; */
  opacity: 1;
  z-index: 10;
}
.homePage .service .serviceRight li span:hover {
  color: #fff;
  cursor: pointer;
}

/* 3V3留学增值规划 */
.plan {
  display: flex;
  margin-bottom: 100rem;
  height: 770rem;
  .left {
    width: 900rem;
    .pic {
      width: 100%;
      height: 100%;
      // background-image: url("../assets/img/homePage/planImg1.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .pic1 {
      background-image: url("../assets/img/homePage/planImg1.png");
    }
    .pic2 {
      background-image: url("../assets/img/homePage/planImg2.png");
    }
    .pic3 {
      background-image: url("../assets/img/homePage/planImg3.693e904f.png");
    }
  }
  .right {
    flex: 1;

    // background-color: #2ecc71;
    background-image: url("../assets/img/homePage/aaaa1.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .bg {
      width: 100%;
      height: 510rem;
      background-color: #fff;
      // margin-top: 146rem;
      // padding-top: 50rem;
      display: flex;
      flex-direction: column;
      // padding-bottom: 85rem;
    }
    .box {
      display: flex;
      flex-direction: column;

      align-items: flex-end;
      width: 664rem;
      // background-color: #2ecc71;
      margin-left: 62rem;
      margin-top: 63rem;

      .title {
        font-size: 48rem;
        font-weight: bold;
        margin-bottom: 30rem;
        span {
          color: #2864b4;
        }
      }
      .imgbox {
        width: 664rem;
        height: 110rem;
        position: relative;
        // background-color: #2ecc71;
        margin-bottom: 37rem;
        img {
          position: absolute;
          top: 0;
          // left: 0;
          width: 664rem;
          height: 110rem;
          // margin-bottom: 37rem;
        }
      }
      // img {
      //   width: 664rem;
      //   height: 110rem;
      //   margin-bottom: 37rem;
      // }
      .msgTitleBox {
        position: relative;
        margin-bottom: 54rem;
        width: 100%;
      }
      .position {
        position: absolute;
        right: 0;
        top: 0;
      }
      .group {
        display: flex;
        // justify-content: flex-end;
        align-items: center;
        // width: 100%;
        // background-color: #f1c40f;

        .msgTitle {
          font-size: 24rem;
          font-weight: 400;
          color: #373737;

          transition: 0.5s;
          transform: translateX(-10rem);
        }

        .titleFadeInOut-enter {
          transform: translateX(45rem);
        }
        .titleFadeInOut-enter-to {
          transform: translateX(-10rem);
        }
        .titleFadeInOut-leave {
          opacity: 1;
        }
        .titleFadeInOut-leave-to {
          opacity: 0;
        }
        .line {
          width: 45rem;
          height: 3rem;
          border-radius: 1.5rem;
          background: #2864b4;

          // transform: scale(0);
          transition: 0.5s;
          transform-origin: right;
        }
        .lineFadeInOut-enter {
          transform: scale(0);
        }
        .lineFadeInOut-enter-to {
          // transform: translateX(0);
          transform: scale(1);
        }
        .lineFadeInOut-leave {
          opacity: 1;
        }
        .lineFadeInOut-leave-to {
          opacity: 0;
        }
        // 淡入淡出
        // .msgTitle-enter-active {
        //   transform: translateX(-10rem);
        // }
        // .msgTitle-leave-active {
        //   transition: opacity 0.3s ease;
        // }
        // .msgTitle-enter,
        // .msgTitle-leave-to {
        //   opacity: 0;
        // }

        // .fadeInOut-enter-active,
        // .fadeInOut-leave-active {
        //   transition: opacity 0.5s ease;
        // }
        // .fadeInOut-enter,
        // .fadeInOut-leave-to {
        //   opacity: 0;
        // }
      }
      .msgBox {
        position: relative;
        width: 100%;
        background-color: #34495e;
      }
      .msg {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 16rem;
        font-weight: 400;
        color: #373737;
        line-height: 30rem;
      }
    }
  }
}

// .homePage .plan {
//   width: 100%;
//   margin-bottom: 100rem;
// }
// .homePage .plan .planBox {
//   display: flex;
// }
// .homePage .plan ::v-deep .el-carousel__indicators--horizontal {
//   display: none;
// }
// .homePage .plan ::v-deep .el-carousel__arrow {
//   width: 60rem;
//   height: 60rem;
//   font-size: 32rem;
// }
// .homePage .plan .planLeft {
//   width: 900rem;
//   height: 770rem;
// }
// .homePage .plan .planLeft img {
//   width: 900rem;
//   height: 770rem;
// }
// .homePage .plan .planRight {
//   flex: 1;
//   background: url("../assets/img/homePage/planImg2.png");
// }
// .homePage .plan .planRight .planRightContent {
//   width: 100%;
//   height: 510rem;
//   background: #fff;
//   margin-top: 146rem;
//   padding-top: 50rem;
// }
// .homePage .plan .planRight .planRightContent .planRightContentBox {
//   width: 660rem;
//   margin: 30rem 62rem;
//   text-align: right;
// }
// .homePage .plan .planRight .planRightContent h2 {
//   font-size: 48rem;
//   font-weight: bold;
// }
// .homePage .plan .planRight .planRightContent h2 span {
//   color: #2864b4;
//   margin-left: 5rem;
// }
// .homePage .plan .planRight .planRightContent img {
//   margin-top: 43rem;
//   width: 664rem;
//   height: 110rem;
// }
// .homePage .plan .planRight .planRightContent .planRightContentBox div {
//   font-size: 24rem;
//   color: #373737;
//   text-align: right;
//   display: flex;
//   justify-content: flex-end;
//   line-height: 34rem;
//   margin-top: 14rem;
//   margin-bottom: 27rem;
// }
// .homePage .plan .planRight .planRightContent .planRightContentBox div div {
//   width: 45rem;
//   height: 3rem;
//   background: #2864b4;
//   margin-left: 10rem;
// }
// .homePage .plan .planRightContent .planRightContentBox .planRightContenTtext {
//   color: #373737;
//   line-height: 30rem;
// }

/* CSO学员来自 */
.homePage .camp {
  margin-bottom: 100rem;
  height: 100%;
}
.homePage .camp .campHeader {
  display: flex;
  margin-bottom: 120rem;
}
.homePage .camp .campHeader h2 {
  color: #373737;
  font-size: 40rem;
  font-weight: bold;
  margin-right: 56rem;
  height: 448rem;
}
.homePage .camp .campHeader .campHeaderRight {
  width: 944rem;
  height: 448rem;
}
.homePage .camp .campHeader .campHeaderRight img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.homePage .camp .campHeader .campHeaderRight ::v-deep .el-carousel__arrow {
  display: none;
}
.homePage .camp .campHeaderRight ::v-deep .el-carousel__indicators--horizontal {
  display: none;
}
.homePage .camp .carouselBox {
  display: flex;
}
.homePage .camp .carouselInfo {
  flex: 1;
  display: flex;
}
.homePage .camp .carouselInfo img {
  width: 150rem;
  height: 150rem;
  margin-top: 55rem;
  margin-right: 29rem;
  border-radius: 50%;
  // overflow: hidden;
  object-fit: cover;
}
.homePage .camp .carouselInfo .infoRight {
  width: 482rem;
  height: 260rem;
  background: url("../assets/img/homePage/dialogImg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.homePage .camp .carouselInfo .infoRight .infoName {
  font-size: 24rem;
  // color: #2864b4;
  margin-top: 32rem;
  margin-left: 52rem;
  font-weight: bold;
}
.homePage .camp .carouselInfo .schoolInfo {
  font-size: 14rem;
  // color: #2864b4;
  margin: 16rem 52rem;
}
.homePage .camp .carouselInfo .schoolInfo span:first-child {
  margin-right: 10rem;
}
.homePage .camp ::v-deep .el-carousel__button {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background: #ccc;
}
.homePage .camp ::v-deep .is-active .el-carousel__button {
  background: #2864b4;
}
.homePage .camp ::v-deep .el-carousel__arrow--left {
  left: 45%;
}
.homePage .camp ::v-deep .el-carousel__arrow--right {
  right: 45%;
}
.homePage .camp ::v-deep .el-carousel__arrow {
  top: 94%;
  color: #fff;
  font-size: 16rem;
  font-weight: bold;
}
/* 选择CSO的理由 */
.homePage .reason {
  min-width: 1380rem !important;
  height: 1050rem;
  margin-bottom: 200rem;
  position: relative;
}
.homePage .reason .reason_1 p:nth-of-type(1) {
  font-size: 40rem;
  font-weight: bold;
}
.homePage .reason .reason_1 p:nth-of-type(2) {
  font-size: 24rem;
  margin-top: 15rem;
}
.homePage .reason .reason_1 p:nth-of-type(2) span {
  font-weight: bold;
}
.homePage .reason .reason_1 p:nth-of-type(3) {
  width: 70rem;
  height: 2rem;
  background: #2864b4;
  margin-top: 34rem;
}
.homePage .reason .reason_2 {
  display: flex;
  // background-color: red;
}
.homePage .reason .reason_2Box {
  display: flex;
  margin-top: 42rem;
  // background-color: red;
}
.homePage .reason .reason_2Box div {
  width: 330rem;
  height: 290rem;
  // padding-bottom: 10rem;
  transition: 0.5s;
  &:hover {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8);
    z-index: 10;
  }
}
.homePage .reason .reason_2Box div:first-child {
  background: #fafafa;
  /* box-shadow: -1rem -7rem 29rem rgba(0, 0, 0, 0.3);
  z-index: 99; */
}
.homePage .reason .reason_2Box div h3 {
  margin-top: -10rem;
  margin-bottom: 10rem;
  overflow: hidden;
  span {
    color: #e8e8e8;
    text-align: center;
    font-size: 70rem;
    font-weight: bold;
    font-family: Arial;
    margin-top: 33rem;

    // transform: translateY(28rem);

    padding: 0;
    transition: 0.5s;
  }
}

// .homePage .reason .reason_2Box div h3 span:hover {
//   transform: translateY(0);
// }
.homePage .reason .reason_2Box div p {
  font-size: 24rem;
  font-weight: bold;
  color: #373737;
  line-height: 30rem;
  text-align: center;
  margin-bottom: 26rem;
  // background-color: #f1c40f;
  // padding: 10rem 0;
  // z-index: 100;
}
.homePage .reason .reason_2Box div span {
  font-size: 14rem;
  color: #373737;
  display: block;
  margin: 0 31rem;
}
.homePage .reason .reason_2Box div:last-child {
  background: #f4f4f4;
}
.homePage .reason .reason_2Img {
  position: absolute;
  top: 15rem;
  // right: -260rem;
  left: 660rem;
  z-index: -1;
  // background-color: red;
  img {
    // width: 990rem;
    height: 500rem;
    object-fit: cover;
  }
}
// .homePage .reason .reason_2Img img {
//   width: 980rem;
//   height: 500rem;
// }
.homePage .reason .reason_3 {
  display: flex;
  z-index: 5;
  // background-color: red;
}
.homePage .reason .reason_3Box {
  display: flex;
}
.homePage .reason .reason_3Box div {
  width: 330rem;
  height: 290rem;
  // padding-bottom: 10rem;
  z-index: 1;
  // background-color: red;
  transition: 0.5s;
  &:hover {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8);
    z-index: 10;
  }
}
// .homePage .reason .reason_3Box div h3 {
//   color: #e8e8e8;
//   text-align: center;
//   font-size: 70rem;
//   font-weight: bold;
//   font-family: Arial;
//   margin-top: 33rem;
// }
.homePage .reason .reason_3Box div h3 {
  margin-top: -10rem;
  margin-bottom: 10rem;
  overflow: hidden;
  span {
    color: #e8e8e8;
    text-align: center;
    font-size: 70rem;
    font-weight: bold;
    font-family: Arial;
    margin-top: 33rem;

    // transform: translateY(28rem);

    padding: 0;
    transition: 0.5s;
  }
}

// .homePage .reason .reason_3Box div h3 span:hover {
//   transform: translateY(0);
// }

.homePage .reason .reason_3Box div p {
  font-size: 24rem;
  font-weight: bold;
  color: #fff;
  line-height: 30rem;
  text-align: center;
  margin-bottom: 26rem;
}
.homePage .reason .reason_3Box div span {
  font-size: 14rem;
  color: #fff;
  display: block;
  margin: 0 31rem;
}
.homePage .reason .reason_3Box div:first-child {
  background: #359fe3;
}
.homePage .reason .reason_3Box div:last-child {
  background: #2864b4;
}
// .homePage .reason .reason_3Box div {
//   background-color: pink;
// }
.homePage .reason .reason_3Img {
  width: 660rem;
  height: 290rem;
  // background-color: red;
  // background-image: url("../assets/img/homePage/reasonImg3.png");
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.homePage .reason .reason_4 {
  display: flex;
}
.homePage .reason .reason_4Box {
  display: flex;
  // &:nth-child(1):hover {
  //   box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8);
  //   z-index: 10;
  // }
  .shadow {
    &:hover {
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8);
      z-index: 10;
    }
  }
}
.homePage .reason .reason_4Box div {
  width: 330rem;
  height: 290rem;
  // padding-bottom: 10rem;
  transition: 0.5s;
  // &:hover {
  //   box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8);
  //   z-index: 10;
  // }
}
// .homePage .reason .reason_4Box div h3 {
//   color: #e8e8e8;
//   text-align: center;
//   font-size: 70rem;
//   font-weight: bold;
//   font-family: Arial;
//   margin-top: 33rem;
// }
.homePage .reason .reason_4Box div h3 {
  margin-top: -10rem;
  margin-bottom: 10rem;
  overflow: hidden;
  span {
    color: #e8e8e8;
    text-align: center;
    font-size: 70rem;
    font-weight: bold;
    font-family: Arial;
    margin-top: 33rem;

    // transform: translateY(28rem);

    padding: 0;
    transition: 0.5s;
  }
}

// .homePage .reason .reason_4Box div h3 span:hover {
//   transform: translateY(0);
// }
.homePage .reason .reason_4Box div p {
  font-size: 24rem;
  font-weight: bold;
  color: #373737;
  line-height: 30rem;
  text-align: center;
  margin-bottom: 26rem;
}
.homePage .reason .reason_4Box div span {
  font-size: 14rem;
  color: #373737;
  display: block;
  margin: 0 31rem;
}
.homePage .reason .reason_4Box div:first-child {
  background: #ffd17a;
}
.homePage .reason .reason_4Box div:last-child {
  background: #ffaf19;
}
.homePage .reason .reason_4Img {
  width: 739rem;
  height: 290rem;
  // position: absolute;
  // bottom: 0;
  // right: -19rem;
  // background-color: red;
  img {
    width: 739rem;
    height: 290rem;
    // width: 100%;
    // height: 100%;
    object-fit: cover;
  }
}
/* 出国留学 */
.homePage .come {
  height: 1150rem;
  background: url("../assets/img/homePage/comeImg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 150rem;
}
.homePage .come .comeBox {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  position: relative;
  width: 1320rem;
  height: 740rem;
  background: #fff;
  // position: relative;
  video {
    position: absolute;
    top: 50%;
    // margin-top: -250rem;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    // width: 1320rem;
    // height: 740rem;
    // background-size: cover;
    object-fit: cover;
    z-index: 1;
  }
}
// .homePage .come .comeBox div img {
//   width: 1320rem;
//   height: 740rem;
//   margin: 30rem;
// }
.homePage .come .comeBox .comeShade {
  width: 100%;
  height: 100%;
  // background: rgba(168, 199, 231, 0.36);

  background-color: rgba(168, 199, 231, 1);
  // background-color: red;
  z-index: 5;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}
// .homePage .come .comeBox .comeShade img {
//   width: 172rem;
//   height: 172rem;
//   margin: 267rem 597rem;
//   cursor: pointer;
// }
.homePage .come ::v-deep .el-dialog__headerbtn {
  font-size: 30rem;
}
/* 更多资讯 */
.homePage .information {
  background: #f2faff;
  padding-top: 116rem;
}
.homePage .information .informationHeader {
  font-size: 48rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #282828;
  text-align: center;
}
.homePage .information .informationInfo {
  display: flex;
  margin-top: 80rem;
  justify-content: space-between;
}
.homePage .information .informationInfo .infoLeft {
  width: 1080rem;
  display: flex;
  flex-wrap: wrap;
}
.homePage .information .informationInfo .infoLeft li {
  width: 530rem;
  height: 290rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  background: #fff;
  cursor: pointer;
  transition: 0.5s;
}
.homePage .information .informationInfo .infoLeft li:hover {
  background: #1f7eff;
  color: #fff;
  cursor: pointer;
}
.homePage .information .informationInfo .infoLeft .active {
  background: #1f7eff;
  color: #fff;
}
.homePage .information .infoLeft .infoLeftBox {
  width: 419rem;
  margin: 26rem auto;
}
.homePage .information .infoLeft .infoLeftBox .infoHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 32rem;
  border-bottom: 2rem solid #ddd;
}
.homePage .information .infoLeft .active .infoHeader {
  border-bottom: 2rem solid #fff;
}
.homePage .information .infoLeft .infoLeftBox .infoHeader .infoHeaderLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.homePage .information .infoLeft .infoLeftBox .infoHeader .infoHeaderLeft span {
  font-size: 36rem;
}
.homePage .information .infoLeft .infoLeftBox .infoHeader .infoHeaderRight {
  font-size: 20rem;
  font-family: Source Han Sans CN;
  line-height: 84rem;
}
.homePage .information .infoLeft .infoContent {
  margin-top: 16rem;
}
.homePage .information .infoLeft .infoContent p {
  font-size: 20rem;
  margin-bottom: 20rem;
  font-family: Source Han Sans CN;
}
.homePage .information .infoLeft .infoContent span {
  line-height: 25rem;
}
.homePage .information .informationInfo .infoRight {
  position: relative;
  width: 316rem;
  height: 582rem;
}
.homePage .information .informationInfo .infoRight img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.homePage .information .moreInfo {
  height: 788rem;
  background: url("../assets/img/homePage/moreImg.png");
  background-repeat: no-repeat;
  background-size: cover;
  // background-position-y: -390rem;
}
.homePage .information .moreInfo .moreInfoHeader {
  padding-top: 63rem;
}
.homePage .information .moreInfo .moreInfoHeader .lookMore {
  width: 200rem;
  height: 50rem;
  border: 1rem solid #707070;
  border-radius: 25rem;
  text-align: center;
  line-height: 50rem;
  margin: 0 auto;
  color: #4a4a4a;
  font-size: 20rem;
  cursor: pointer;
}
.homePage .information .moreInfo .moreInfoHeader .lookMore:hover {
  border-color: #1f7eff;
  color: #1f7eff;
}
.homePage ::v-deep .botIntroduce .contactLine {
  background: #fff !important;
}
</style>
