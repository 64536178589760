<template>
  <!-- 左侧侧边工具 -->
  <ul class="tooll">
    <div>
      <img :src="erweima3" />
	</div>
  </ul>
</template>
 
<script>
  let myDate = new Date();
  let dateNum = myDate.getDate(); 
export default {

  data() {
    return {
	  erweima3: "http://cso-iec.oss-cn-hongkong.aliyuncs.com/cso_school/comm/qrcode/"+dateNum+".jpg",
    }
  },
}
</script>
<style lang="scss" scoped>
/*左侧工具栏*/
.tooll {
  position: fixed;
  top: 286rem;
  padding: 10rem;
  z-index: 9999;
}
</style>