import axios from "axios";
import Vue from "vue";

const service = axios.create({
  // baseURL: "http://58.48.199.198:10030",
  baseURL: "https://www.csoschool.com/",//线上地址
  // baseURL: "http://test.csasedu.com:10030",//线上测试地址
  timeout: 5000,
});

// console.log(service);

service.interceptors.request.use(
  (config) => {
    // console.log(config);
    return config;
  },
  (error) => {
    // console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // console.log(res);
    return res;
  },
  (error) => {
    // console.log(error);
    return Promise.reject(error);
  }
);

export default service;
