import Vue from "vue";
import VueRouter from "vue-router";
import homePage from "../views/homePage.vue";

Vue.use(VueRouter);

const routes = [
  // 首页
  {
    path: "/",
    name: "homePage",
    component: homePage,
  },
  // 学分项目
  {
    path: "/credit",
    name: "credit",
    component: () => import("../views/credit.vue"),
  },
  // 学分项目
  {
    path: "/singleCredit",
    name: "singleCredit",
    component: () => import("../views/service/singleCredit.vue"),
  },
  // 热门课程
  {
    path: "/hotCourse",
    name: "hotCourse",
    component: () => import("../views/hotCourse.vue"),
  },
  // 转学分流程
  {
    path: "/turnGrade",
    name: "turnGrade",
    component: () => import("../views/turnGrade.vue"),
  },
  {
    path: "/case",
    name: "case",
    component: () => import("../views/case.vue"),
  },
  // 3v1服务(校园大使)
  {
    path: "/dashi",
    name: "dashi",
    component: () => import("../views/service/dashi.vue"),
  },
  // 3v1服务(教授)
  {
    path: "/jiaoshou",
    name: "jiaoshou",
    component: () => import("../views/service/jiaoshou.vue"),
  },
  // 3v1服务(课程顾问)
  {
    path: "/guwen",
    name: "guwen",
    component: () => import("../views/service/guwen.vue"),
  },
  // 关于我们
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about/index.vue"),
  },
  // 新闻资讯详情
  {
    path: "/newsDetails",
    name: "newsDetails",
    component: () => import("../views/about/newsDetails.vue"),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 路由跳转始终滚动到顶部

    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
