<template>
  <div class="botIntroduce">
    <div class="w contact">
      <div class="contactLeft">
        <div class="contactLeft_1">
          <p><span>出国留学</span><span>创造留学圈</span></p>
          <p>和学长学姐一起get学分！</p>
        </div>
        <div class="contactLeft_2">
          <p class="contactUs">联系我们/Contact us</p>
          <ul>
            <li>
              <img src="../assets/img/homePage/email.png" alt="" />
              <span>transcript@canadasummer.com</span>
            </li>
            <li>
              <img src="../assets/img/homePage/phone.png" alt="" />
              <span
                ><i>17764086400 中国（CN）</i> 437-985-5721加拿大（CA）</span
              >
            </li>
            <li>
              <img src="../assets/img/homePage/location.png" alt="" />
              <span
                ><p>湖北省武汉市洪山区群光中心3406（中国）</p>
                <p>
                  616-4798 Yonge Street, North York, ON M2N 0G3（Canada）
                </p></span
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="contactRight">
        <div class="homeCode">
          <img :src="erweima" alt="" />
          <!-- <p>官方公众号</p> -->
        </div>
        <!-- <div class="redBookCode">
          <img src="../assets/img/homePage/redBookCode.png" alt="" />
          <p>小红书二维码</p>
        </div> -->
      </div>
    </div>
    <div class="contactLine w"></div>
    <div class="logoBox w">
      <div class="logoLeft">
        <!-- <img src="../assets/img/homePage/sxlogo.png" alt="" /> -->
      </div>
      <div class="logoRight">
        <ul>
          <li>CSO合作企业：</li>
          <li>
            <img src="../assets/img/homePage/xslogo.png" alt="" />
            <span>西索教育科技集团</span>
          </li>
          <li>
            <img src="../assets/img/homePage/mglogo.png" alt="" />
            <span>摩根大通</span>
          </li>
          <li>
            <img src="../assets/img/homePage/phlogo.png" alt="" />
            <span>普华永道</span>
          </li>
          <li>
            <img src="../assets/img/homePage/gglogo.png" alt="" />
            <span>谷歌</span>
          </li>
        </ul>
        <ul>
          <li>CSO合作平台：</li>
          <li>
            <img src="../assets/img/homePage/xhslogo.png" alt="" />
            <span>CSO国际暑校、小啾啾嗎</span>
          </li>
          <li>
            <img src="../assets/img/homePage/dylogo.png" alt="" />
            <span>小啾啾嗎、Hello，留学生</span>
          </li>
          <li>
            <img src="../assets/img/homePage/billogo.png" alt="" />
            <span>cso留学课堂</span>
          </li>
          <li>
            <img src="../assets/img/homePage/ttlogo.png" alt="" />
            <span>cso留学说、黑马求职营</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  let myDate = new Date();
  let dateNum = myDate.getDate(); 
export default {
  data() {
    return {
	  erweima: "http://cso-iec.oss-cn-hongkong.aliyuncs.com/cso_school/comm/qrcode/"+dateNum+".jpg",
      showDialog: false // 控制弹窗的显示与隐藏
    }
  },
};
  
</script>

<style>
/* 底部介绍 */
.botIntroduce .contact {
  display: flex;
  justify-content: space-between;
  margin-top: 59rem;
}
.botIntroduce .contact .contactLeft_1 p:first-child span:first-child {
  font-size: 26rem;
  font-weight: bold;
  color: #333;
  margin-right: 15rem;
}
.botIntroduce .contact .contactLeft_1 p:first-child span:last-child {
  font-size: 40rem;
  font-weight: bold;
  color: #2864b4;
  font-style: italic;
}
.botIntroduce .contact .contactLeft_1 p:last-child {
  font-size: 24rem;
  margin-top: 20rem;
  font-family: SourceHanSansCN-Normal;
}
.botIntroduce .contact .contactLeft_2 .contactUs {
  font-weight: bold;
  color: #2864b4;
  font-size: 26rem;
  margin-top: 53rem;
}
.botIntroduce .contact .contactLeft_2 ul {
  margin-top: 24rem;
}
.botIntroduce .contact .contactLeft_2 ul li {
  display: flex;
  margin-bottom: 20rem;
  font-size: 16rem;
}
.botIntroduce .contact .contactLeft_2 ul li:nth-of-type(1) img {
  width: 28rem;
  height: 20rem;
}
.botIntroduce .contact .contactLeft_2 ul li:nth-of-type(2) img {
  width: 28rem;
  height: 27rem;
}
.botIntroduce .contact .contactLeft_2 ul li:nth-of-type(3) img {
  width: 26rem;
  height: 35rem;
}
.botIntroduce .contact .contactLeft_2 ul li:nth-of-type(3) span p:first-child {
  margin-bottom: 10rem;
}
.botIntroduce .contact .contactLeft_2 ul li span {
  margin-left: 25rem;
}
.botIntroduce .contact .contactRight {
  display: flex;
  margin-top: 82rem;
}
.botIntroduce .contact .contactRight div {
  text-align: center;
  margin-right: 46rem;
}
.botIntroduce .contact .contactRight img {
  width: 220rem;
  height: 220rem;
  object-fit: contain;
}
.botIntroduce .contact .contactRight p {
  color: #2864b4;
  font-size: 18rem;
  margin-top: 20rem;
}
.botIntroduce .contactLine {
  height: 1rem;
  background: #c2c2c2;
  margin-top: 54rem;
}
.botIntroduce .logoBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40rem;
  /* background-color: #95a5a6; */
}
.botIntroduce .logoBox .logoLeft {
  width: 109rem;
  height: 89rem;
  margin-top: -18rem;
  /* background-color: #8e44ad; */
  background-image: url("../assets/img/homePage/sxlogo.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.botIntroduce .logoBox .logoRight {
  margin-left: 40rem;
  /* background-color: #f1c40f; */
}
.botIntroduce .logoBox .logoRight ul {
  display: flex;
  justify-content: center;
  align-items: center;
  /* line-height: 40rem; */
  margin-bottom: 20rem;
  /* background-color: #f1c40f; */
}
.botIntroduce .logoBox .logoRight ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #373737;
  font-size: 16rem;
  width: 240rem;
  margin-right: 30rem;
}
.botIntroduce .logoBox .logoRight ul li span {
  margin-left: 12rem;
  font-family: Source Han Sans CN;
}
.botIntroduce .logoBox .logoRight ul li:nth-of-type(1) {
  width: 150rem;
  margin-right: 0;
}
.botIntroduce .logoBox .logoRight ul:first-child li:nth-of-type(2) img {
  width: 63rem;
  height: 35rem;
}
.botIntroduce .logoBox .logoRight ul:first-child li:nth-of-type(3) img {
  width: 136rem;
  height: 35rem;
}
.botIntroduce .logoBox .logoRight ul:first-child li:nth-of-type(4) img {
  width: 57rem;
  height: 44rem;
}
.botIntroduce .logoBox .logoRight ul:first-child li:nth-of-type(5) img {
  width: 91rem;
  height: 29rem;
  margin-top: 5rem;
}
.botIntroduce .logoBox .logoRight ul:last-child li:nth-of-type(2) img {
  width: 41rem;
  height: 41rem;
}
.botIntroduce .logoBox .logoRight ul:last-child li:nth-of-type(3) img {
  width: 41rem;
  height: 41rem;
}
.botIntroduce .logoBox .logoRight ul:last-child li:nth-of-type(4) img {
  width: 105rem;
  height: 34rem;
}
.botIntroduce .logoBox .logoRight ul:last-child li:nth-of-type(5) img {
  width: 37rem;
  height: 36rem;
}
</style>
