<template>
  <div class="header">
    <div class="container">
      <div class="headerLeft" @click="toHome">
        <img src="../assets/img/homePage/logo.png" alt="" />
      </div>
      <ul class="headerRight">
        <li
          class="headerRightLi"
          v-for="(item, index) in navList"
          :key="index"
          :class="{ active: headIndex === index }"
          @click.stop="linkUrl(item)"
        >
          <div class="item">
            {{ item.name }}
          </div>
          <!-- <ul class="subnav" v-if="item.navArr.length > 0 && showList"> -->
          <ul class="subnav" v-if="item.navArr.length > 0">
            <!-- <ul class="subnav"> -->
            <li
              v-for="(item2, index2) in item.navArr"
              :key="index2"
              @click.stop="linkLiUrl(item, item2, index2)"
            >
              {{ item2.creditName }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      activeIndex2: "1",
      showList: false,
      navList: [
        { name: "首页", navArr: [] },
        { name: "国际学分项目", navArr: [] },
        { name: "热门课程", navArr: [] },
        {
          name: "转学分",
          navArr: [
            { id: "", creditName: "转学分流程" },
            { id: "", creditName: "转学分成功案例" },
          ],
        },
        {
          name: "3V1服务",
          navArr: [
            { id: "", creditName: "校园大使" },
            { id: "", creditName: "教授" },
            { id: "", creditName: "课程顾问" },
          ],
        },
        { name: "关于我们", navArr: [] },
      ],
      headIndex: 0,
      headLiIndex: 0,
      navText: "",
    };
  },
  props: ["headerIndex", "headerLiIndex"],
  // computed: {
  //   ...mapState(["crediArr"]),
  // },
  methods: {
    ...mapMutations(["initCrediArr"]),
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    getData() {
      var _this = this;
      this.$http.post("/web", {}).then(function (resp) {
        let res = resp.data;

        if (res.code == 200) {
          _this.navList.forEach((v) => {
            if (v.name == "国际学分项目") {
              res.data.credit.forEach((v2) => {
                v.navArr.push(v2);
              });
            }
          });
        }
      });
    },
    toHome() {
      this.$router.push({ path: "/" });
    },
    linkUrl(item) {
      // console.log(item, "linkUrl");
      if (item.name == "首页") {
        this.$router.push({ path: "/" });
      } else if (item.name == "国际学分项目") {
        this.$router
          .push({
            path: "credit",
            query: {
              creditId: JSON.stringify(item.navArr[0].id),
            },
          })
          .catch(() => {});
        // this.initCrediArr(item.navArr[0]);
        // console.log(item.navArr[0], "item.navArr[0]");
        this.$http
          .post("/web/credit", { id: item.navArr[0].id })
          .then((res) => {
            // console.log(res.data.data.csoCourse, "toptoptop");
            // this.creditInfo = res.data.data;
            // console.log(item.navArr[index2], "item.navArr[index2]");
            item.navArr[0].csoCourse = res.data.data.csoCourse;
            this.initCrediArr(item.navArr[0], "top");
          });
      } else if (item.name == "热门课程") {
        this.$router.push({ path: "hotCourse" }).catch(() => {});
      } else if (item.name == "转学分") {
        this.$router.push({ path: "turnGrade" }).catch(() => {});
      } else if (item.name == "3V1服务") {
        if (this.navText == "教授") {
          this.$router.push({ path: "jiaoshou" }).catch(() => {});
        } else if (this.navText == "课程顾问") {
          this.$router.push({ path: "guwen" }).catch(() => {});
        } else {
          this.$router.push({ path: "dashi" }).catch(() => {});
        }
      } else if (item.name == "关于我们") {
        this.$router.push({ path: "about" }).catch(() => {});
      }
    },
    linkLiUrl(item, item2, index2) {
      // console.log(item, "item");
      // console.log(item2, "item2");

      if (item.name == "国际学分项目") {
        // this.$router
        //   .push({
        //     path: "credit",
        //     // path: "singleCredit",
        //     query: {
        //       creditId: JSON.stringify(item2.id),
        //       // crediArr: item.navArr,
        //       // index: index2,
        //     },
        //   })
        //   .catch(() => {});
        // console.log(
        //   this.$fk_URL + "credit?creditId=" + item2.id,
        //   "999999000000"
        // );
        window.location.href =
          // `http://192.168.10.107:8080/#/credit?creditId=` + item2.id;
          // `http://59.175.19.39:10030/#/credit?creditId=` + item2.id;
          `${this.$fk_URL}credit?creditId=` + item2.id;
        this.$emit("change", item2.id);
        // $fk_URL + "/credit?creditId=" + item2.id;
        // console.log(item2.id, "top");

        // this.$http.post("/web/credit", { id: item2.id }).then((res) => {
        //   // console.log(res.data.data.csoCourse, "toptoptop");
        //   // this.creditInfo = res.data.data;
        //   // console.log(item.navArr[index2], "item.navArr[index2]");
        //   item.navArr[index2].csoCourse = res.data.data.csoCourse;
        //   this.initCrediArr(item.navArr[index2], "top");
        // });

        // console.log(index2, "index2");
        // this.initCrediArr(item.navArr[index2], "top");
        // window.location.reload();
      }
      if (item2.creditName == "校园大使") {
        this.$router.push({ path: "dashi" }).catch(() => {});
        this.navText = item2.creditName;
      } else if (item2.creditName == "教授") {
        this.$router.push({ path: "jiaoshou" }).catch(() => {});
        this.navText = item2.creditName;
      } else if (item2.creditName == "课程顾问") {
        this.$router.push({ path: "guwen" }).catch(() => {});
        this.navText = item2.creditName;
      } else if (item2.creditName == "转学分成功案例") {
        this.$router.push({ path: "case" }).catch(() => {});
        this.navText = item2.creditName;
      } else if (item2.creditName == "转学分流程") {
        this.$router.push({ path: "turnGrade" }).catch(() => {});
        this.navText = item2.creditName;
      }
      // location.reload();
    },
  },
  mounted() {
    this.headIndex = this.headerIndex;
    this.headLiIndex = this.headerLiIndex;
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  width: 100%;
  height: 110rem;
  background-color: rgba(0, 0, 0, 0.7);
  // background-color: #8e44ad;
  top: 0;
  left: 0;
  z-index: 999;
  // background-color: #8e44ad;
  .el-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #000000b3;
    height: 100rem;
    .el-menu-item:hover {
      background-color: #000000b3;
    }
    .el-menu-item {
      line-height: 100rem;
    }
  }
  ::v-deep {
    //去掉选中el-menu-item时的下划线
    .el-menu--horizontal > .el-menu-item {
      border-bottom: none !important;
      background-color: #000000b3;
    }
    .el-menu--horizontal > .el-menu-item.is-active {
      border-bottom: none !important;
      background-color: #000000b3;
    }
    .el-menu-item {
      height: 100%;
      &:hover {
        background-color: #000000b3;
      }
    }
    .el-menu--horizontal > .el-submenu .el-submenu__title {
      height: 100rem;
      line-height: 100rem;
      &:hover {
        background-color: #000000b3;
      }
    }
  }
}
.container {
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.header .headerLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130rem;
  // width: 73rem;
  // height: 61rem;
  height: 100%;
  // position: absolute;
  // left: 10%;
  // top: 50%;
  // transform: translateY(-50%);
  // background-color: red;
}
.header .headerLeft img {
  // width: 73rem;
  // height: 61rem;
  width: 240%;
  object-fit: contain;
  cursor: pointer;
  margin-left: -130px;
}
.header .headerRight {
  // position: absolute;
  // left: 53%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  // flex: 1;
  height: 100%;
  display: flex;
  // justify-items: center;
  justify-content: space-around;
  align-items: baseline;
  // align-items: center;
  // background-color: red;
  // margin-right: 150rem;
}
.header .headerRight .headerRightLi {
  // flex: 1;
  color: rgba(255, 255, 255, 0.5);
  width: 200rem;
  line-height: 110rem;
  text-align: center;
  font-size: 18rem;
  transition: 0.5s;
  // background-color: red;
}
.header .headerRight .headerRightLi:hover {
  cursor: pointer;
  color: #fff;
}
.header .headerRight .active {
  /* color: #0054ff; */
  color: #fff;
  font-size: 22rem;
}
.header .headerRight .headerRightLi:hover .subnav li {
  display: block;

  // opacity: 1;
  // transform: translateY(0);

  animation-name: showList;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  // animation-direction: alternate;
}
@keyframes showList {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.header .headerRight .subnav {
  width: 200rem;
  // height: 50rem;
  // background-color: #3498db;
  // display: none;
  // transform: translateY(-100%);
  // opacity: 0;
  // visibility: hidden;
  // position: absolute;
  // top: 0;
  // left: 0;

  overflow: hidden;
  // transition: 0.5s;
  z-index: 0;
}

.header .headerRight .subnav li {
  width: 200rem;
  height: 50rem;
  background: rgba(255, 255, 255, 0.9);
  color: #373737;
  line-height: 50rem;
  text-align: center;
  font-size: 16rem;
  cursor: pointer;

  display: none;

  // transform: translateY(-100%);
  // opacity: 0;
  transition: 0.5s;
}
@keyframes disList {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}
.header .headerRight .subnav li:hover {
  // background: #f8f8f8;
  background-color: #2864b4;
  color: #ffffff;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}
.header .headerRight .subnav .active2 {
  background: #f8f8f8;
}
</style>
